"use client";

import { LayoutContainerV2 } from "@/design-system/components/layout-container/LayoutContainerV2";
import { Box, Flex, Skeleton } from "@radix-ui/themes";
import React from "react";

function CityPageSkeleton() {
  return (
    <Flex direction={"column"} gap={{ initial: "0", md: "5" }} width={"100%"}>
      {/* Navigation */}
      <Flex
        justify={"between"}
        px={{ initial: "5", md: "7" }}
        pt={{ initial: "5", md: "8" }}
        pb={{ initial: "8", md: "5" }}
        width={"100%"}
      >
        <Skeleton height={"25px"} width={"172px"} />
        <Flex gap={"5"} display={{ initial: "none", md: "flex" }}>
          <Skeleton height={"25px"} width={"100px"} />
          <Skeleton height={"25px"} width={"100px"} />
          <Skeleton height={"25px"} width={"70px"} />
          <Skeleton height={"25px"} width={"70px"} />
          <Skeleton height={"25px"} width={"100px"} />
        </Flex>
        <Box display={{ initial: "block", md: "none" }}>
          <Skeleton height={"25px"} width={"25px"} />
        </Box>
      </Flex>

      {/* Header Image */}
      <Skeleton height={{ initial: "318px" }} width={{ initial: "100%" }} />

      {/* Header */}
      <LayoutContainerV2>
        <Flex
          direction={"column"}
          align={{ initial: "center", md: "start" }}
          pt={{ initial: "7" }}
          pb={"6"}
          gap={"4"}
        >
          <Skeleton
            height={{ initial: "50px", md: "70px" }}
            width={{ initial: "70%", md: "600px" }}
            mb={"6"}
          />
          <Skeleton height={"25px"} width={{ initial: "100%" }} />
          <Skeleton height={"25px"} width={{ initial: "100%" }} />
        </Flex>
      </LayoutContainerV2>

      {/* 3 Pillars */}
      <LayoutContainerV2 display={{ initial: "none", md: "initial" }}>
        <Flex justify={"between"} gap={"9"}>
          <Flex direction={"column"} gap={"7"} py={"5"} width={"100%"}>
            <Skeleton height={"30px"} width={"50%"} />
            <Skeleton height={"125px"} width={"100%"} />
            <Skeleton height={"40px"} width={"30%"} />
          </Flex>
          <Flex direction={"column"} gap={"7"} py={"5"} width={"100%"}>
            <Skeleton height={"30px"} width={"50%"} />
            <Skeleton height={"125px"} width={"100%"} />
            <Skeleton height={"40px"} width={"30%"} />
          </Flex>
          <Flex direction={"column"} gap={"7"} py={"5"} width={"100%"}>
            <Skeleton height={"30px"} width={"50%"} />
            <Skeleton height={"125px"} width={"100%"} />
            <Skeleton height={"40px"} width={"30%"} />
          </Flex>
        </Flex>
      </LayoutContainerV2>

      {/* Sub Header */}
      <LayoutContainerV2>
        <Flex direction={"column"} align={"center"} pt={{ initial: "7", md: "8" }} pb={"6"} gap={{ initial: "7", md: "5" }}>
          <Skeleton height={"45px"} width={{ initial: "100%", md: "400px" }} />
          <Box display={{ initial: "none", md: "block" }}>
            <Skeleton
              height={"25px"}
              width={{ initial: "90%", md: "600px" }}
              maxWidth={"100%"}
            />
          </Box>
          <Skeleton
            height={{ initial: "200px" }}
            width={{ initial: "100%" }}
            my={{ initial: "0", md: "7" }}
          />
        </Flex>
      </LayoutContainerV2>
    </Flex>
  );
}

export default CityPageSkeleton;
